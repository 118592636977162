import React from 'react'
import Image from 'gatsby-image'
import {graphql, StaticQuery, Link} from 'gatsby'
import styled from '@emotion/styled'
import TextLoop from 'react-text-loop'
// import {Carousel} from 'react-responsive-carousel'
import {Carousel} from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './slider.css'

import Layout from '../containers/layout'
import SEO from '../components/seo'
import Container from '../components/container'

// import ReactTextRotator from 'react-text-rotator'

import styles from '../components/home.module.css'

// import BlockContent from '../components/portableText'

// import styles from '../components/home.module.css'
// import HomeHero from '../components/HomeHero'

export default class Index extends React.Component {
  render () {
    return (
      <Layout>
        <Container className={styles.homeHero}>
          <StaticQuery
            query={graphql`
            query sanityHome {
              sanityHome {
                title
                homeSlideShow {
                  _key
                  title
                  link
                  slideImage {
                    asset {
                      fluid(maxWidth: 646) {
                        ...GatsbySanityImageFluid
                      }
                    }
                  }
                }
                ctaTitle
                ctaOptions {
                  _key
                  title
                  link
                }
                seoSettings {
                  title
                  description
                }
              }
            }
          `}
            render={data => (
              <>
                {data.sanityHome.seoSettings && data.sanityHome.seoSettings.title && data.sanityHome.seoSettings.description && (<SEO title={data.sanityHome.seoSettings.title} description={data.sanityHome.seoSettings.description} />)}

                <div className={styles.homeHero}>
                  <Carousel interval={9000} showArrows showThumbs={false} showStatus={false} showIndicators={false} autoPlay stopOnHover={false} useKeyboardArrows dynamicHeight infiniteLoop emulateTouch>
                    {data.sanityHome.homeSlideShow.map(slide => (
                      <div key={slide._key}>
                        {slide.link ? (

                          <Link to={slide.link}>
                            <Image fluid={slide.slideImage.asset.fluid} alt={slide.slideImage.title} />
                          </Link>
                        ) : (
                          <Image fluid={slide.slideImage.asset.fluid} alt={slide.slideImage.title} />
                        )}

                      </div>
                    ))}
                  </Carousel>
                </div>

                <h2 className={styles.homeText}>
                  {data.sanityHome.ctaTitle}{' '}
                  <TextLoop>
                    {data.sanityHome.ctaOptions.map(node => (
                      <Link key={node._key} to={node.link}>{node.title}</Link>
                    ))}
                  </TextLoop>
                </h2>
              </>
            )}
          />
        </Container>
      </Layout>
    )
  }
}
